import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "pretitle": "Introducing",
  "title": "Skolar Sans",
  "tags": "Typography",
  "date": "2014-12-07T00:00:00.000Z"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p><a parentName="p" {...{
        "href": "https://www.rosettatype.com"
      }}>{`Rosetta`}</a>{` recently released `}<a parentName="p" {...{
        "href": "https://www.rosettatype.com/SkolarSans"
      }}>{`a `}<em parentName="a">{`sans`}</em>{` version of the beautiful Skolar`}</a>{`. It follows the proportions of its predecessor but with a more modern twist that you expect from sans serifs.`}</p>
    <p>{`It has lots of personality, it’s packed with open type features and it’s screen-optimized. I can’t wait to use it on a project.`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.rosettatype.com/SkolarSans"
      }}>{`The font is currently 30% off, `}<em parentName="a">{`a bargain`}</em>{`.`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      